<div class="artifactDetails">
  <h3>{{ 'artifactDetails.title' | translate }}</h3>
  <div class="details">
    @if (artifact !== undefined && !loading) {
      <table>
        <tr>
          <td>{{ 'artifactTable.productFamily' | translate }}:</td>
          <td>{{ artifact.productFamily }}</td>
        </tr>
        <tr>
          <td>{{ 'artifactTable.itemNumbers' | translate }}:</td>
          <td>
            @if (artifact.itemNumbers.length > 0) {
              <div>
                @for (num of artifact.itemNumbers | slice: 0 : 3; track num) {
                  <div>
                    @if (artifact.itemNumbers.length > 1) {
                      <a> {{ '• ' + num }}</a>
                    } @else {
                      <a> {{ num }}</a>
                    }
                  </div>
                }
                @if (artifact.itemNumbers.length === 4) {
                  <div>
                    <a>{{ '• ' + artifact.itemNumbers[3] }}</a>
                  </div>
                }
                @if (artifact.itemNumbers.length > 4) {
                  <div>
                    <button
                      class="moreBtn"
                      (click)="allNumbers(artifact.productFamily, artifact.artifactVersion, artifact.itemNumbers)"
                    >
                      {{ 'artifactTable.more' | translate }}
                    </button>
                  </div>
                }
              </div>
            }
          </td>
        </tr>
        <tr>
          <td>{{ 'artifactTable.filename' | translate }}:</td>
          <td>{{ artifact.fileName }}</td>
        </tr>
        <tr>
          <td>{{ 'artifactTable.filesize' | translate }}:</td>
          <td>{{ artifact.fileLength | humanReadableBytes }}</td>
        </tr>
        <tr>
          <td>{{ 'artifactTable.version' | translate }}:</td>
          <td>{{ artifact.artifactVersion }}</td>
        </tr>
        <tr>
          <td>{{ ('artifactTable.created' | translate) + ' ' + ('artifactTable.by' | translate) }}:</td>
          <td>{{ artifact.createdBy }}</td>
        </tr>
        <tr>
          <td>{{ ('artifactTable.created' | translate) + ' ' + ('artifactTable.on' | translate) }}:</td>
          <td>
            {{ artifact.createdAt | localizedDate: 'mediumDateZF' }}
          </td>
        </tr>
      </table>
    }
    @if (artifact === undefined && !loading) {
      <span>{{ 'artifactDetails.noDataFound' | translate }}</span>
    }
  </div>
  @if (!loading) {
    <div class="buttons">
      <button
        pButton
        class="ui-button-secondary"
        icon="pi pi-small pi-chevron-left"
        label="{{ 'artifactDetails.backToList' | translate }}"
        (click)="backToList()"
      ></button>
      @if (artifact !== undefined) {
        <button
          pButton
          icon="pi pi-small pi-download"
          label="{{ 'artifactDetails.download' | translate }}"
          (click)="onDownload()"
        ></button>
      }
    </div>
  }
</div>
