<!-- Core header display -->
<div class="header">
  <img src="../../../assets/images/SICK_Logo_RGB.svg" class="logo" alt="Sick AG" />
  <div class="application_title">{{ applicationTitle }}</div>
  <div class="divider"></div>
  <app-menu icon="pi pi-large pi-globe" offset="9" title="{{ 'header.language' | translate }}">
    @for (lang of languages; track lang) {
      <app-menu-item
        [label]="lang.label"
        [disabled]="!lang.available || false"
        [icon]="lang.code === language ? 'pi pi-radio-button-checked' : ''"
        (selected)="onLanguageChange(lang.code)"
      >
      </app-menu-item>
    }
  </app-menu>
  <app-menu icon="pi pi-large pi-apps" iconSize="28" offset="9" title="{{ 'header.apps' | translate }}">
    @for (app of apps; track $index) {
      <app-menu-item [label]="app.label" [icon]="app.icon" (selected)="onOpenInNewWindow(app.url)"></app-menu-item>
    }
  </app-menu>
  <app-menu icon="pi pi-large pi-user" offset="9" title="{{ 'header.user' | translate }}">
    <app-menu-item [label]="'header.sickProfile' | translate" (selected)="onShowProfile()"></app-menu-item>
    <app-menu-item [label]="'header.changePassword' | translate" (selected)="onChangePassword()"></app-menu-item>
    <app-menu-item [label]="'header.logout' | translate" (selected)="onLogout()"></app-menu-item>
  </app-menu>
</div>
