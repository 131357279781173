<span class="p-float-label">
  <p-autoComplete
    class="ui-required"
    inputId="devices-autocomplete"
    [suggestions]="(devices$ | async) ?? []"
    [(ngModel)]="selectedDevice"
    optionLabel="label"
    [delay]="500"
    [required]="true"
    (completeMethod)="onFilterDevices($event)"
    [emptyMessage]="'uploadDialog.no-device' | translate"
  ></p-autoComplete>
  <label for="devices-autocomplete">{{ 'uploadDialog.device-placeholder' | translate }}</label>
</span>

<span class="p-float-label">
  <input
    id="version-input"
    [ngClass]="{ 'bad-input': displayVersionHelp }"
    type="text"
    pInputText
    required
    [(ngModel)]="version"
    (ngModelChange)="validateVersion($event)"
    (focusout)="onVersionLeaveFocus()"
    [tooltipDisabled]="!displayVersionHelp"
    [pTooltip]="'uploadDialog.version-errors.schema-invalid' | translate"
    [escape]="false"
    [hideDelay]="100"
    tooltipStyleClass="version-tooltip"
    tooltipEvent="focus"
    appendTo="body"
  />
  <label for="version-input">{{ 'uploadDialog.version' | translate }}</label>
</span>
<div class="upload">
  <button
    pButton
    (click)="fileInput.choose()"
    [disabled]="file"
    type="button"
    label="{{ 'uploadDialog.chooseFile' | translate }}"
    icon="pi pi-plus"
  ></button>
  <p-fileUpload
    #fileInput
    name="files"
    [showCancelButton]="false"
    [showUploadButton]="false"
    [hidden]="true"
    (onSelect)="handleFile($event)"
    (onRemove)="resetFile()"
    [fileLimit]="1"
  ></p-fileUpload>
</div>

@if (file) {
  <div class="selected-view">
    <div>
      <span class="file-name">{{ file.name }}</span>
    </div>
    <div>
      <span class="file-size">{{ file.size | humanReadableBytes }}</span>
    </div>
    <button
      pButton
      type="button"
      (click)="removeFile()"
      class="ui-button-bare"
      label="{{ 'uploadDialog.delete' | translate }}"
      [disabled]="isUploading"
    ></button>
  </div>
}

@if (upload) {
  <div class="upload-progress">
    <p-progressBar
      [value]="upload.progress"
      [mode]="upload.state === 'PENDING' ? 'indeterminate' : 'determinate'"
    ></p-progressBar>
  </div>
}

@if (selectedDevice?.hideUploader === false) {
  <app-callout severity="info">{{ 'uploadDialog.hideUploaderInfo' | translate }}</app-callout>
}

<div class="buttons">
  <button
    pButton
    label="{{ 'uploadDialog.cancel' | translate }}"
    (click)="onCancelClick()"
    class="ui-button-secondary"
    [disabled]="isUploading"
  ></button>
  <button
    pButton
    label="{{ 'uploadDialog.save' | translate }}"
    (click)="onAddClick()"
    [disabled]="
      !artifactVersionValid ||
      file === null ||
      isUploading ||
      selectedDevice === undefined ||
      selectedDevice.deviceId.length === 0
    "
  ></button>
</div>
