<div class="group-button">
  <button
    pButton
    type="button"
    label="{{ 'addDeviceDialog.groupManagement' | translate }}"
    class="ui-button-secondary grouplink"
    (click)="openGroupUI()"
    icon="pi pi-group"
  ></button>
</div>

<div class="groups">
  <span class="p-float-label">
    <p-multiSelect
      #editGroupSelect
      id="editGroup-select"
      class="group-multiselect ui-required"
      emptyMessage="{{ 'addDeviceDialog.noSearchEntered' | translate }}"
      emptyFilterMessage="{{ 'addDeviceDialog.noGroupSearch' | translate }}"
      selectedItemsLabel="{{ 'addDeviceDialog.selectedItemsLabel' | translate }}"
      [(ngModel)]="selectedEditGroups"
      [options]="editGroups"
      [optionValue]="'id'"
      [optionLabel]="'display_name'"
      [filter]="true"
      (onFilter)="onFilterEditGroup($event)"
      ><ng-template pTemplate="filtericon"><i class="pi pi-search icon"></i></ng-template
    ></p-multiSelect>
    <label for="editGroup-select">{{ 'addDeviceDialog.selectEditGroup' | translate }}</label>
  </span>
</div>

<div class="groups">
  <span class="p-float-label">
    <p-multiSelect
      #downloadGroupSelect
      id="downloadGroup-select"
      class="group-multiselect"
      emptyMessage="{{ 'addDeviceDialog.noSearchEntered' | translate }}"
      emptyFilterMessage="{{ 'addDeviceDialog.noGroupSearch' | translate }}"
      selectedItemsLabel="{{ 'addDeviceDialog.selectedItemsLabel' | translate }}"
      [(ngModel)]="selectedDownloadGroups"
      [options]="downloadGroups"
      [optionValue]="'id'"
      [optionLabel]="'display_name'"
      [filter]="true"
      (onFilter)="onFilterDownloadGroup($event)"
      ><ng-template pTemplate="filtericon"><i class="pi pi-search icon"></i></ng-template
    ></p-multiSelect>
    <label for="downloadGroup-select">{{ 'addDeviceDialog.selectDownloadGroup' | translate }}</label>
  </span>
</div>

<span class="p-float-label">
  <input id="productFamily-input" type="text" pInputText required [(ngModel)]="productFamily" />
  <label for="productFamily-input">{{ 'addDeviceDialog.productFamily' | translate }}</label>
</span>

@for (number of partNumbers; track number; let i = $index) {
  <div class="artNumberInput" formArrayName="items">
    <span class="p-float-label">
      <input
        id="artNumber-input"
        type="text"
        pInputText
        (ngModelChange)="validateItemNumbers($event, i)"
        [ngClass]="{ 'bad-input': !itemNumberValid }"
        [(ngModel)]="number.value"
      />
      <label for="artNumber-input">{{ 'addDeviceDialog.itemNumber' | translate }}</label>
    </span>
    <button pButton type="button" (click)="removeNumber(i)" class="ui-button-bare" icon="pi pi-times"></button>
  </div>
}

<button
  pButton
  type="button"
  class="ui-button-secondary addNumberButton"
  (click)="addNumber()"
  icon="pi pi-plus"
></button>

<app-callout class="hideUploaderInfo" severity="info">{{ 'addDeviceDialog.hideUploaderInfo' | translate }}</app-callout>

<p-checkbox
  class="hide-uploader-check"
  label="{{ 'addDeviceDialog.hideUploader' | translate }}"
  [(ngModel)]="hideUploader"
  [binary]="true"
  (onChange)="onHideUploaderChanged($event)"
></p-checkbox>

@if (hideUploader) {
  <div class="groups">
    <span class="p-float-label">
      <p-autoComplete
        class="ui-required"
        inputId="supportgroup-autocomplete"
        [suggestions]="(supportGroups$ | async) ?? []"
        [(ngModel)]="selectedSupportGroup"
        [delay]="500"
        [required]="true"
        (completeMethod)="onFilterSupportGroups($event)"
        [emptyMessage]="'addDeviceDialog.noGroupSearch' | translate"
      ></p-autoComplete>
      <label for="supportgroup-autocomplete">{{ 'addDeviceDialog.supportGroup' | translate }}</label>
    </span>
  </div>
}

<div class="buttons">
  <button
    pButton
    label="{{ 'addDeviceDialog.cancel' | translate }}"
    (click)="onCancelClick()"
    class="ui-button-secondary"
  ></button>
  @if (!this.config.data?.isEditMode) {
    <button
      pButton
      label="{{ 'addDeviceDialog.save' | translate }}"
      (click)="onAddClick()"
      [disabled]="
        productFamily === '' ||
        !itemNumberValid ||
        selectedEditGroups.length === 0 ||
        (hideUploader && !selectedSupportGroup)
      "
    ></button>
  }
  @if (this.config.data?.isEditMode) {
    <button
      pButton
      label="{{ 'addDeviceDialog.update' | translate }}"
      (click)="onUpdateClick()"
      [disabled]="
        productFamily === '' ||
        !itemNumberValid ||
        selectedEditGroups.length === 0 ||
        (hideUploader && !selectedSupportGroup)
      "
    ></button>
  }
</div>
